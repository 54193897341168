<template>
  <b-card
    no-body
    class="coupon-card"
  >
    <b-card-header class="advanced-search-block">
      <div class="d-flex align-items-center justify-content-between mb-1">
        <h4>Danh sách phiếu đặt hàng</h4>
        <div
          class="d-flex"
          style="gap: 8px;"
        >
          <!-- <b-button
            variant="info"
          >
            <feather-icon
              icon="DownloadIcon"
              class="mr-50"
            />
            Xuất dữ liệu
          </b-button> -->
          <b-button
            variant="primary"
            to="add"
          >
            <feather-icon
              icon="PlusCircleIcon"
              class="mr-50"
            />
            Tạo phiếu đặt
          </b-button>
        </div>
      </div>
      <div class="d-flex justify-content-between cus-group-filter">
        <div class="flex-1">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="modelFilter.search"
              :placeholder="$t('Search')"
              type="text"
              class=""
              @keyup.enter="handleFilter"
            />
          </b-input-group>
        </div>
        <div class="d-flex cus-group">
          <v-select
            v-model="modelFilter.status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="statusOptions"
            :placeholder="$t('Status')"
            style="width: 200px;"
          />
          <flat-pickr
            v-model="modelFilter.expected_date"
            class="form-control"
            :config="{ dateFormat: 'd-m-Y' }"
            :placeholder="$t('Pick a date')"
            style="width: 200px;"
          />
          <v-select
            v-model="modelFilter.supplier"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="providers"
            :placeholder="$t('Supplier')"
            style="width: 200px;"
          />
        </div>
      </div>
      <div
        class="d-flex align-items-center"
        :class="{'mt-1': modelFilter.search || modelFilter.status || modelFilter.expected_date || modelFilter.supplier}"
        style="gap: 8px;"
      >
        <b-badge
          v-if="modelFilter.search"
          variant="light-dark cursor-pointer"
          @click="modelFilter.search = null"
        >
          {{ modelFilter.search }}
          <span class="text-danger">X</span>
        </b-badge>
        <b-badge
          v-if="modelFilter.status"
          variant="light-dark cursor-pointer"
          @click="modelFilter.status = null"
        >
          {{ modelFilter.status.name }}
          <span class="text-danger">X</span>
        </b-badge>
        <b-badge
          v-if="modelFilter.expected_date"
          variant="light-dark cursor-pointer"
          @click="modelFilter.expected_date = null"
        >
          {{ modelFilter.expected_date }}
          <span class="text-danger">X</span>
        </b-badge>
        <b-badge
          v-if="modelFilter.supplier"
          variant="light-dark cursor-pointer"
          @click="modelFilter.supplier = null"
        >
          {{ modelFilter.supplier.name }}
          <span class="text-danger">X</span>
        </b-badge>
      </div>
    </b-card-header>

    <b-card-body>
      <!-- table -->
      <vue-good-table
        ref="promotion-products"
        class="custom-good-table"
        style-class="vgt-table"
        :columns="columns"
        :rows="items"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <!-- Table: Head -->
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span>
            {{ $t(props.column.label) }}
          </span>
        </template>

        <!-- Table: Rows -->
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Warehouses -->
          <span v-if="props.column.field === 'id'">
            <router-link :to="`edit/${props.row.id}`">PDH-{{ props.row.id }}</router-link>
          </span>

          <!-- Column: expected_date -->
          <span v-else-if="props.column.field === 'expected_date'">
            <span class="text-nowrap">{{ formatDate(props.row.expected_date, '-') }}</span>
          </span>

          <!-- Column: Providers -->
          <span v-else-if="props.column.field === 'supplier'">
            <span class="text-nowrap">{{ props.row.supplier ? props.row.supplier.name : '--' }}</span>
          </span>

          <!-- Column: Warehouses -->
          <span v-else-if="props.column.field === 'storage_id'">
            <span class="text-nowrap">{{ props.row.storages_name }}</span>
          </span>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge
              class="d-block"
              :variant="mixStatus('requestGood', props.row.status).color"
            >
              {{ mixStatus('requestGood', props.row.status).name }}
            </b-badge>
          </span>

          <!-- Column: Quantity -->
          <span v-else-if="props.column.field === 'totalQuantity'">
            <b-progress
              v-model="props.row.currentQuantity"
              :max="props.row.total_quantity"
              variant="success"
            />
            <div class="mb-0 text-right">
              <small>{{ props.row.currentQuantity || '--' }} / {{ props.row.total_quantity }}</small>
            </div>
          </span>

          <!-- Column: Total price -->
          <span v-else-if="props.column.field === 'total_price'">
            <span
              v-if="props.row.vat !== 0"
              class="text-nowrap"
            >{{ unitFormatOriginal(props.row.total_price + (props.row.total_price * props.row.vat / 100)) }}</span>
            <span
              v-else
              class="text-nowrap"
            >{{ unitFormatOriginal(props.row.total_price) }}</span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['10','20', '30', '50','100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                v-model="currentPageOriginal"
                :total-rows="totalRows"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-body>
  </b-card>
</template>

<script>
/* eslint-disable array-callback-return */
import {
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  VBTooltip,
  BFormInput,
  // BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormSelect,
  BPagination,
  BBadge,
  BProgress,
  // BCollapse,
  // BRow,
  // BCol,
  VBToggle,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { VueGoodTable } from 'vue-good-table'
import VSelect from 'vue-select'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    flatPickr,
    VueGoodTable,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    // BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BPagination,
    BBadge,
    BProgress,
    // BCollapse,
    // BRow,
    // BCol,
    VSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      pageLength: 10,
      currentPageOriginal: 1,
      totalRows: 20,
      searchTerm: '',
      columns: [
        {
          field: 'id',
          label: 'ID',
          tdClass: 'text-left',
          thClass: 'text-left',
          sortable: false,
        },
        {
          field: 'expected_date',
          label: 'Expected date',
          thClass: 'text-left',
          sortable: false,
        },
        {
          field: 'supplier',
          label: 'Supplier',
          sortable: false,
          thClass: 'text-left',
        },
        {
          field: 'storage_id',
          label: 'Warehouse',
          sortable: false,
          thClass: 'text-left',
        },
        {
          field: 'status',
          label: 'Status',
          sortable: false,
          width: '150px',
          thClass: 'text-left',
        },
        {
          field: 'totalQuantity',
          label: 'Quantity',
          sortable: false,
          thClass: 'text-right',
        },
        {
          field: 'total_price',
          label: 'Total price',
          sortable: false,
          tdClass: 'text-right',
          thClass: 'text-right',
        },
      ],
      items: [],
      filter: {
        date: null,
        warehouse: null,
        provider: null,
      },
      status: {
        1: 'Chờ duyệt',
        2: 'Đã duyệt',
        3: 'Không duyệt',
      },
      statusOptions: [
        {
          id: 1,
          name: 'Chờ duyệt',
        },
        {
          id: 2,
          name: 'Đã duyệt',
        },
        {
          id: 3,
          name: 'Không duyệt',
        },
      ],
      warehouses: {
        1: 'Kho mặc định',
      },
      providers: [],
      modelFilter: {
        search: null,
        modelFilter: null,
        status: null,
        supplier: null,
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-info',
        2 : 'light-success',
        3 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },

  },
  watch: {
    // eslint-disable-next-line object-shorthand
    '$route'() {
      this.loadList()
    },
    currentPageOriginal() {
      this.loadList()
    },
    pageLength() {
      this.loadList()
    },
    // eslint-disable-next-line object-shorthand
    'modelFilter.status'() {
      this.handleFilter()
    },
    // eslint-disable-next-line object-shorthand
    'modelFilter.expected_date'() {
      this.handleFilter()
    },
    // eslint-disable-next-line object-shorthand
    'modelFilter.supplier'() {
      this.handleFilter()
    },
  },
  mounted() {
    this.loadList()
  },
  created() {
    this.loadList()
    this.loadSupplier()
  },
  methods: {
    resetFilter() {
      this.modelFilter = {
        search: null,
        modelFilter: null,
        status: null,
        supplier: null,
      }
      this.$router.replace({})
    },
    handleFilter() {
      const params = {}
      if (this.modelFilter.search) params.search = this.modelFilter.search
      if (this.modelFilter.expected_date) params.expected_date = this.modelFilter.expected_date
      if (this.modelFilter.status) params.status = this.modelFilter.status.id
      if (this.modelFilter.supplier) params.supplier = this.modelFilter.supplier.id_number
      this.$router.replace({
        query: params,
      }).catch(() => {})
      this.loadList()
    },
    async loadSupplier() {
      const res = await this.mixGet('/suppliers', { limit: 1000 })
      if (res.status) {
        if (res.data) {
          this.providers = res.data.items
        }
      }
    },
    async loadList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        let params = ''
        if (this.$route.query.search) {
          if (this.$route.query.search.includes('PDH-')) {
            const t = this.$route.query.search.split('-')
            params += `&id=${t[1]}`
          } else {
            params += `&id=${this.$route.query.search}`
          }
        }
        if (this.$route.query.expected_date) params += `&expected_date=${this.dateConvert(this.$route.query.expected_date, 'year')}`
        if (this.$route.query.status) params += `&status=${this.$route.query.status}`
        if (this.$route.query.supplier) params += `&supplier=${this.$route.query.supplier}`
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/requests_goods?site_id=${siteId}&page=${this.currentPageOriginal}&limit=${this.pageLength}&sort[]=created_at,desc${params}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.perPage = res.data.data.count_page
            this.currentPage = res.data.data.current_page
            this.totalRows = res.data.data.total

            // this.items = res.data.data.items
            const temp = []
            res.data.data.items.map(item => {
              temp.push({
                ...item,
                currentQuantity: item.receiving_good ? item.receiving_good.receiving_goods_product[0].quantity : '',
              })
            })
            this.items = temp
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.custom-good-table .vgt-table {
  font-size: 14px;

  thead th {
    vertical-align: middle;
    text-align: center;
    padding-right: 0.75em;
    white-space: nowrap;
  }
  td {
    vertical-align: middle;
  }
}
</style>

<style lang="sass" scoped>
.advanced-search
  &-block
    display: block

  &-row
    display: grid
    grid-template-columns: 1fr 200px
    gap: 16px

.cus-group-filter
  gap: 16px

  .flex-1
    flex: 1

  .cus-group
    gap: 16px
</style>
